<template>
    <div>
        <EditNews :addInformation="{title:'Sector'}"></EditNews>
    </div>
</template>
<script>
import EditNews from "../Common/EditField.vue"
export default {
    components:{
        EditNews
    }
}
</script>
<style>
    
</style>